body.inverse,
body.inverse .form-control {
  color: rgba(255,255,255, 0.84);
}
body.inverse .modal,
body.inverse .panel-default,
body.inverse .card,
body.inverse .modal .form-control,
body.inverse .panel-default .form-control,
body.inverse .card .form-control {
  background-color: initial;
  color: initial;
}
.inline-form {
  display: inline;
}
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 0;
}
.form-horizontal .radio {
  margin-bottom: 10px;
}
.form-horizontal label {
  text-align: right;
}
.form-horizontal label.control-label {
  margin: 0;
}
.form-group.is-focused .checkbox label {
  color: rgba(0,0,0, 0.26);
}
.form-group.is-focused .checkbox label:hover,
.form-group.is-focused .checkbox label:focus {
  color: rgba(0,0,0, .54);
}
fieldset[disabled] .form-group.is-focused .checkbox label {
  color: rgba(0,0,0, 0.26);
}
fieldset[disabled] .form-group.is-focused .togglebutton label {
  color: rgba(0,0,0, 0.26);
}
.form-group.is-focused .radio label {
  color: rgba(0,0,0, 0.26);
}
.form-group.is-focused .radio label:hover,
.form-group.is-focused .radio label:focus {
  color: rgba(0,0,0, .54);
}
fieldset[disabled] .form-group.is-focused .radio label {
  color: rgba(0,0,0, 0.26);
}
.form-control {
  height: 38px;
  padding: 7px 0;
  font-size: 16px;
  line-height: 1.42857143;
}
.form-group-sm .form-control {
  height: 24px;
  padding: 3px 0;
  font-size: 11px;
  line-height: 1.5;
}
.form-group-sm select.form-control {
  height: 24px;
  line-height: 24px;
}
.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}
.form-group-sm .form-control-static {
  height: 24px;
  min-height: 33px;
  padding: 4px 0;
  font-size: 11px;
  line-height: 1.5;
}
.form-group-lg .form-control {
  height: 44px;
  padding: 9px 0;
  font-size: 18px;
  line-height: 1.3333333;
}
.form-group-lg select.form-control {
  height: 44px;
  line-height: 44px;
}
.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}
.form-group-lg .form-control-static {
  height: 44px;
  min-height: 40px;
  padding: 10px 0;
  font-size: 18px;
  line-height: 1.3333333;
}
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 8px;
}
.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 30px;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 8px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 12.9999997px;
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 4px;
    font-size: 11px;
  }
}
.form-control,
.form-group .form-control {
  border: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#009688), to(#009688)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#009688, #009688), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#009688, #009688), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#009688, #009688), linear-gradient(#D2D2D2, #D2D2D2);
  -webkit-background-size: 0 2px, 100% 1px;
          background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center -webkit-calc(100% - 1px);
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition: background 0s ease-out;
       -o-transition: background 0s ease-out;
          transition: background 0s ease-out;
  float: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
}
.form-control::-moz-placeholder,
.form-group .form-control::-moz-placeholder {
  color: #BDBDBD;
  font-weight: 400;
}
.form-control:-ms-input-placeholder,
.form-group .form-control:-ms-input-placeholder {
  color: #BDBDBD;
  font-weight: 400;
}
.form-control::-webkit-input-placeholder,
.form-group .form-control::-webkit-input-placeholder {
  color: #BDBDBD;
  font-weight: 400;
}
.form-control[readonly],
.form-group .form-control[readonly],
.form-control[disabled],
.form-group .form-control[disabled],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
  background-color: rgba(0, 0, 0, 0);
}
.form-control[disabled],
.form-group .form-control[disabled],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
  background-image: none;
  border-bottom: 1px dotted #D2D2D2;
}
.form-group {
  position: relative;
}
.form-group.label-static label.control-label,
.form-group.label-placeholder label.control-label,
.form-group.label-floating label.control-label {
  position: absolute;
  pointer-events: none;
  -webkit-transition: 0.3s ease all;
       -o-transition: 0.3s ease all;
          transition: 0.3s ease all;
}
.form-group.label-floating label.control-label {
  will-change: left, top, contents;
}
.form-group.label-placeholder:not(.is-empty) label.control-label {
  display: none;
}
.form-group .help-block {
  position: absolute;
  display: none;
}
.form-group.is-focused .form-control {
  outline: none;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#009688), to(#009688)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#009688, #009688), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#009688, #009688), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#009688, #009688), linear-gradient(#D2D2D2, #D2D2D2);
  -webkit-background-size: 100% 2px, 100% 1px;
          background-size: 100% 2px, 100% 1px;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
}
.form-group.is-focused .form-control .material-input:after {
  background-color: #009688;
}
.form-group.is-focused label,
.form-group.is-focused label.control-label {
  color: #006cb2;
}
.form-group.is-focused.label-placeholder label,
.form-group.is-focused.label-placeholder label.control-label {
  color: #BDBDBD;
}
.form-group.is-focused .help-block {
  display: block;
}
.form-group.has-warning .form-control {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.form-group.has-warning.is-focused .form-control {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ff5722), to(#ff5722)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#ff5722, #ff5722), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#ff5722, #ff5722), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#ff5722, #ff5722), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group.has-warning label.control-label,
.form-group.has-warning .help-block {
  color: #ff5722;
}
.form-group.has-error .form-control {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.form-group.has-error.is-focused .form-control {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f44336), to(#f44336)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#f44336, #f44336), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#f44336, #f44336), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group.has-error label.control-label,
.form-group.has-error .help-block {
  color: #f44336;
}
.form-group.has-success .form-control {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.form-group.has-success.is-focused .form-control {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4caf50), to(#4caf50)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#4caf50, #4caf50), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#4caf50, #4caf50), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group.has-success label.control-label,
.form-group.has-success .help-block {
  color: #4caf50;
}
.form-group.has-info .form-control {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.form-group.has-info.is-focused .form-control {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#03a9f4), to(#03a9f4)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#03a9f4, #03a9f4), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#03a9f4, #03a9f4), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#03a9f4, #03a9f4), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group.has-info label.control-label,
.form-group.has-info .help-block {
  color: #03a9f4;
}
.form-group textarea {
  resize: none;
}
.form-group textarea ~ .form-control-highlight {
  margin-top: -11px;
}
.form-group select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.form-group select ~ .material-input:after {
  display: none;
}
.form-control {
  margin-bottom: 7px;
}
.form-control::-moz-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400;
}
.form-control:-ms-input-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400;
}
.form-control::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400;
}

.form-group {
  padding-bottom: 7px;
  margin: 28px 0 0 0;
}
.form-group .form-control {
  margin-bottom: 7px;
}
.form-group .form-control::-moz-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400;
}
.form-group .form-control:-ms-input-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400;
}
.form-group .form-control::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400;
}
.form-group .checkbox label,
.form-group .radio label,
.form-group label {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400;
}
.form-group label.control-label {
  font-size: 12px;
  line-height: 1.07142857;
  color: #BDBDBD;
  font-weight: 400;
  margin: 16px 0 0 0;
}
.form-group .help-block {
  margin-top: 0;
  font-size: 12px;
}
.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label {
  top: -7px;
  font-size: 16px;
  line-height: 1.42857143;
}
.label-static label.control-label,
.label-floating.is-focused label.control-label,
.label-floating:not(.is-empty) label.control-label {
  top: -30px;
  left: 0;
  font-size: 12px;
  line-height: 1.07142857;
}
.form-group.label-floating input.form-control:-webkit-autofill ~ label.control-label label.control-label {
  top: -30px;
  left: 0;
  font-size: 12px;
  line-height: 1.07142857;
}
.form-group.form-group-sm {
  padding-bottom: 3px;
  margin: 21px 0 0 0;
}
.form-group.form-group-sm .form-control {
  margin-bottom: 3px;
}
.form-group.form-group-sm .form-control::-moz-placeholder {
  font-size: 11px;
  line-height: 1.5;
  color: #BDBDBD;
  font-weight: 400;
}
.form-group.form-group-sm .form-control:-ms-input-placeholder {
  font-size: 11px;
  line-height: 1.5;
  color: #BDBDBD;
  font-weight: 400;
}
.form-group.form-group-sm .form-control::-webkit-input-placeholder {
  font-size: 11px;
  line-height: 1.5;
  color: #BDBDBD;
  font-weight: 400;
}
.form-group.form-group-sm .checkbox label,
.form-group.form-group-sm .radio label,
.form-group.form-group-sm label {
  font-size: 11px;
  line-height: 1.5;
  color: #BDBDBD;
  font-weight: 400;
}
.form-group.form-group-sm label.control-label {
  font-size: 9px;
  line-height: 1.125;
  color: #BDBDBD;
  font-weight: 400;
  margin: 16px 0 0 0;
}
.form-group.form-group-sm .help-block {
  margin-top: 0;
  font-size: 9px;
}
.form-group.form-group-sm.label-floating label.control-label,
.form-group.form-group-sm.label-placeholder label.control-label {
  top: -11px;
  font-size: 11px;
  line-height: 1.5;
}
.form-group.form-group-sm.label-static label.control-label,
.form-group.form-group-sm.label-floating.is-focused label.control-label,
.form-group.form-group-sm.label-floating:not(.is-empty) label.control-label {
  top: -25px;
  left: 0;
  font-size: 9px;
  line-height: 1.125;
}
.form-group.form-group-sm.label-floating input.form-control:-webkit-autofill ~ label.control-label label.control-label {
  top: -25px;
  left: 0;
  font-size: 9px;
  line-height: 1.125;
}
.form-group.form-group-lg {
  padding-bottom: 9px;
  margin: 30px 0 0 0;
}
.form-group.form-group-lg .form-control {
  margin-bottom: 9px;
}
.form-group.form-group-lg .form-control::-moz-placeholder {
  font-size: 18px;
  line-height: 1.3333333;
  color: #BDBDBD;
  font-weight: 400;
}
.form-group.form-group-lg .form-control:-ms-input-placeholder {
  font-size: 18px;
  line-height: 1.3333333;
  color: #BDBDBD;
  font-weight: 400;
}
.form-group.form-group-lg .form-control::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 1.3333333;
  color: #BDBDBD;
  font-weight: 400;
}
.form-group.form-group-lg .checkbox label,
.form-group.form-group-lg .radio label,
.form-group.form-group-lg label {
  font-size: 18px;
  line-height: 1.3333333;
  color: #BDBDBD;
  font-weight: 400;
}
.form-group.form-group-lg label.control-label {
  font-size: 14px;
  line-height: 0.99999998;
  color: #BDBDBD;
  font-weight: 400;
  margin: 16px 0 0 0;
}
.form-group.form-group-lg .help-block {
  margin-top: 0;
  font-size: 14px;
}
.form-group.form-group-lg.label-floating label.control-label,
.form-group.form-group-lg.label-placeholder label.control-label {
  top: -5px;
  font-size: 18px;
  line-height: 1.3333333;
}
.form-group.form-group-lg.label-static label.control-label,
.form-group.form-group-lg.label-floating.is-focused label.control-label,
.form-group.form-group-lg.label-floating:not(.is-empty) label.control-label {
  top: -32px;
  left: 0;
  font-size: 14px;
  line-height: 0.99999998;
}
.form-group.form-group-lg.label-floating input.form-control:-webkit-autofill ~ label.control-label label.control-label {
  top: -32px;
  left: 0;
  font-size: 14px;
  line-height: 0.99999998;
}
select.form-control {
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
}
.form-group.is-focused select.form-control {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #D2D2D2;
}
select.form-control[multiple],
.form-group.is-focused select.form-control[multiple] {
  height: 85px;
}
.input-group-btn .btn {
  margin: 0 0 7px 0;
}
.form-group.form-group-sm .input-group-btn .btn {
  margin: 0 0 3px 0;
}
.form-group.form-group-lg .input-group-btn .btn {
  margin: 0 0 9px 0;
}
.input-group .input-group-btn {
  padding: 0 12px;
}
.input-group .input-group-addon {
  border: 0;
  background: transparent;
}
.form-group input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.navbar.navbar-danger .navbar-form .form-group input.form-control::-moz-placeholder,
.navbar.navbar-danger .navbar-form input.form-control::-moz-placeholder {
  color: rgba(255,255,255, 0.84);
}
.navbar.navbar-danger .navbar-form .form-group input.form-control:-ms-input-placeholder,
.navbar.navbar-danger .navbar-form input.form-control:-ms-input-placeholder {
  color: rgba(255,255,255, 0.84);
}
.navbar.navbar-danger .navbar-form .form-group input.form-control::-webkit-input-placeholder,
.navbar.navbar-danger .navbar-form input.form-control::-webkit-input-placeholder {
  color: rgba(255,255,255, 0.84);
}