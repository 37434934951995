.container {
	max-width: 1200px;
	width: 96%;
}
body {
	font-family: 'proxima_nova_rgregular';
	overflow-y: scroll;
}
a:hover,
a:focus {
	text-decoration: none;
	outline: none;
}
select:focus {
	outline: none;
}
.italic {
	font-style: italic;
}
.p-n-l {
	font-family: 'proxima_novalight';
}
.p-n-b {
	font-family: 'proxima_nova_rgbold';
}
.m-r-80 {
	margin-right: 80px;
}
.m-l-80 {
	margin-left: 80px;
}
.m-r-250 {
	margin-right: 250px;
}
.m-l-250 {
	margin-left: 250px;
}
.p-t-185 {
	padding-top: 185px;
}
.p-t-40 {
	padding-top: 40px;
}
.p-t-120 {
	padding-top: 120px;
}
.p-t-80 {
	padding-top: 80px;
}
.my-circle-outer-border {
	height: 136px;
	width: 136px;
	border-radius: 100px;
	background-color: #E3F1FA;
	padding: 8px;
	margin: 0 auto;
}
.my-circle {
	height: 120px;
	width: 120px;
	border-radius: 100px;
	border: 1px solid #C4DE79;
	background-color: #ffffff;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}
.top-section {
	min-height: 1005px;
	background-image: url('../images/grey.gif');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.top-section .navbar-default {
	border: none;
    top: 33px;
	background-color: transparent;
    transition: top 1s ease;
}
.top-section .navbar-default .container .navbar-brand img {
	height: 126px;
	-webkit-transition: all 0.5s ease; /* Safari */
    transition: all 0.5s ease;
}
.top-section .navbar-default .navbar-brand {
	height: auto;
	padding: 5px 0;
	margin-left: 0;
}
.top-section .navbar-collapse li a {
	font-family: 'proxima_nova_rgbold';
	font-size: 18px;
	color: #0067aa;
	padding: 5px 25px;
	border-radius: 4px;
	text-transform: uppercase;
}
.top-section .navbar-collapse li .invite,
.top-section .navbar-collapse li .invite:hover,
.top-section .navbar-collapse li .invite:focus {
	background-color: #ffffff;
	font-size: 13px;
	color: #0083cb;
	padding: 5px 10px;
}
.top-section .navbar-collapse li.login .invite {
	margin-left: 10px;
}
.top-section-text {
	margin-top: 200px;
}
.top-section-text h2 {
	font-size: 50px;
	margin: 0;
	text-transform: uppercase;
	color: #000000;
	line-height: 1;
}
.top-section-text p {
	font-family: 'proxima_novalight';
	font-size: 16px;
	color: #000000;
	line-height: 1.2;
	margin-top: 10px;
}
.welcome-section .img-sec {
	background: url('../images/grey.gif');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 1000px;
	background-position: right;
}
.welcome-section .img-sec .text-container {
	float: right;
	padding-right: 20px;
}
.welcome-section .list-inline {
	max-width: 600px;
	width: 100%;
	margin: 0 auto;
}
.welcome-section .list-inline li {
	width: 270px;
	margin: 20px 10px;
}
.welcome-section .list-inline li .heading {
	min-height: 50px;
	margin: 15px 0;
}
.welcome-section .list-inline li h4 {
	font-family: 'proxima_novalight';
	font-size: 22px;
	color: #000000;
}
.welcome-section .list-inline li p {
	font-family: 'proxima_novalight';
	font-size: 16px;
	color: #989898;
}
.img-sec {
	width: 47%;
	float: left;
}
.text-sec {
	width: 53%;
	float: left;
}
.text-sec h4 {
	font-family: 'proxima_novalight';
	font-size: 42px;
	margin-bottom: 30px;
	line-height: 1;
}
.text-container {
	max-width: 325px;
	width: 100%;
	padding-left: 20px;
}
.text-container h1 {
	font-family: 'proxima_nova_rgbold';
	font-size: 45px;
	color: #ffffff;
	margin-bottom: 35px;
}
.text-container h1 span {
	font-family: 'proxima_novalight';
	font-size: 55px;
}
.text-container h1 span big {
	font-size: 75px;
}
.text-container .top-part {
	padding-bottom: 30px;
	margin-bottom: 55px;
	border-bottom: 1px solid #4EA8DC;
}
.text-container .top-part p {
	font-family: 'proxima_novalight';
	font-size: 42px;
	color: #ffffff;
	line-height: 1;
}
.text-container .bottom-part p {
	font-family: 'proxima_novalight';
	font-size: 16px;
	color: #ffffff;
	line-height: 1.2;
	margin-bottom: 25px;
}
.about-section .text-sec .content {
	float: right;
	max-width: 550px;
	width: 100%;
}
.about-section .text-sec h4 {
	color: #4cbfba;
	margin-bottom: 90px;
}
.about-section .text-sec ul {
	margin-bottom: 60px;
	list-style-type: none;
}
.about-section .text-sec li {
	margin-bottom: 10px;
	clear: both;
	overflow: hidden;
}
.about-section .text-sec li p {
	font-family: 'proxima_novalight';
	font-size: 18px;
	color: #888787;
	margin-bottom: 0;
	width: 95%;
	float: left;
	display: inline-block;
}
.about-section .text-sec li .fa {
	color: #4cbfba;
	float: right;
	font-size: 20px;
	margin-top: 3px;
	display: inline-block;
}
.about-section .text-sec p {
	font-family: 'proxima_novalight';
	font-size: 16px;
	color: #000000;
	margin-bottom: 25px;
}
.about-section .text-sec p a {
	font-family: 'proxima_nova_rgbold';
	font-size: 17px;
	color: #4cbfba;
}
.about-section .img-sec {
	background: url('../images/grey.gif');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 1000px;
	background-position: left;
}
.about-section .text-container .top-part {
	border-bottom-color: #83d2cf;
}
.about-section .text-container h3 {
	font-size: 55px;
	margin-top: 0;
	color: #fff;
}
.key-feature-section .img-sec {
	background: url('../images/grey.gif');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 1000px;
	background-position: right;
}
.key-feature-section .img-sec .text-container {
	float: right;
	padding-right: 20px;
}
.key-feature-section .text-container h3 {
	line-height: 1;
	font-size: 55px;
	color: #fff;
}
.key-feature-section .text-container .top-part {
	border-bottom-color: #bf99c0;
}
.key-feature-section .down-app {
	margin-top: 50px;
}
.key-feature-section .down-app h4 {
	text-transform: uppercase;
	color: #ffffff;
	font-family: 'proxima_nova_rgbold';
	font-size: 18px;
	margin-bottom: 20px;
}
.key-feature-section .text-sec h4 {
	font-family: 'proxima_novalight';
	font-size: 42px;
	color: #a36da7;
	line-height: 1;
	margin-bottom: 30px;
}
.key-feature-section .text-sec .content {
	max-width: 600px;
	width: 100%;
}
.key-feature-section .features {
	min-height: 135px;
	margin-bottom: 15px;
	padding: 0 30px;
}
.key-feature-section .features h5 {
	font-family: 'proxima_novalight';
	margin-top: 0;
	font-size: 22px;
}
.key-feature-section .features p {
	font-family: 'proxima_nova_rgregular';
	font-size: 15px;
	color: #888787;
}
.key-feature-section .features hr {
	border-top: 1px solid #e5e5e5;
	margin-bottom: 15px;
	margin-top: 15px;
}
.key-feature-section .col-md-12.features.no-margin {
	margin-bottom: 0;
}
.key-feature-section .img-sec .down-app .margin {
	margin-right: 10px;
}


.pricing-package-section .img-sec {
	background: url('../images/grey.gif');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 1000px;
	background-position: left;
}
.pricing-package-section .img-sec .pre-book a {
	font-family: 'proxima_nova_rgbold';
	font-size: 18px;
	color: #9cca1d;
	background-color: #FFFFFF;
	padding: 10px 25px;
	border-radius: 25px;
	display: inline-block;
	margin-top: 20px;
}
.pricing-package-section .text-container .top-part {
	border-bottom-color: #bbda62
}
.pricing-package-section .text-container h3 {
	font-size: 55px;
	line-height: 1;
	color: #fff;
}
.pricing-package-section .text-sec h4 {
	color: #9cca1d;
	font-size: 55px;
}
.pricing-package-section .text-sec .content {
	max-width: 620px;
	float: right;
}
.pricing-package-section .text-sec .package-name h4 {
	font-family: 'proxima_nova_rgbold';
	font-size: 35px;
	color: #9cca1d;
	line-height: 1;
	margin-bottom: 10px;
}
.pricing-package-section .text-sec .package-name h4 span {
	font-family: 'proxima_novalight';
	font-size: 28px;
	line-height: 1;
}
.pricing-package-section .text-sec p {
	font-family: 'proxima_novalight';
	font-size: 14px;
	color: #9a9a9a;
	line-height: 1.2;
}
.pricing-package-section .text-sec p.small-text {
	font-size: 13px;
}
.pricing-package-section .text-sec .offer del {
	font-family: 'proxima_novalight';
	font-size: 22px;
	color:#7FC1E4;
}

.pricing-package-section .text-sec .offer del span {
    	color: #9cca1d;
}

.pricing-package-section .text-sec .offer .price {
	font-family: 'proxima_nova_rgbold';
	font-size: 24px;
	color: #9cca1d;
}
.pricing-package-section .text-sec .my-circle-outer-border {
	position: relative;
	background-color: #f1eff2;
	margin-bottom: 10px;
}
.pricing-package-section .text-sec .badge {
	font-family: 'proxima_novalight';
	font-size: 14px;
	border-radius: 50px;
	padding: 10px;
	position: absolute;
	top: 90%;
	width: 80px;
	left: 0;
	right: 0;
	margin: 0 auto;
	border: 5px solid #ffffff;
}
.pricing-package-section .text-sec .features p {
	font-family: 'proxima_novalight';
	font-size: 18px;
	color: #807e7e;
}
.pricing-package-section .package .buy-btn {
	background-color: #9ccb1d;
	color: #ffffff;
	font-family: 'proxima_nova_rgbold';
	font-size: 20px;
	line-height: 1;
	padding: 10px 15px;
	border-radius: 25px;
	margin-bottom: 10px;
}
.pricing-package-section .contact-link {
	margin: 0 20px;
}
.pricing-package-section .contact-link a {
	font-size: 22px;
	color: #ffffff;
	padding: 10px 30px;
	display: inline-block;
	margin-top: 20px;
	border-radius: 25px;
	background-color: #696f55;
}
.contact-section {
	background: url('../images/grey.gif');
	background-size: cover;
	background-repeat: no-repeat;
	padding-top: 90px;
	padding-bottom: 70px;
}
.contact-section h2 {
	font-family: 'proxima_nova_rgbold';
	font-size: 55px;
	color: #000000;
}
.contact-section p {
	font-family: 'proxima_novalight';
	font-size: 20px;
	color: #000000;
}
.contact-section h2 span {
	font-family: 'proxima_novalight';
}
.mail-form {
	position: relative;
}
.mail-form .spinner {
	display: none;
    width:100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align:center;
    margin-left: -50px;
    margin-top: -100px;
    z-index:2;
    overflow: auto;
    opacity: 0.5;
}
.contact-form .spinner {
	top: 30%;
}
.mail-form form label.control-label {
	font-family: 'proxima_novalight';
	font-size: 18px;
	color: #000000;
}
.mail-form form .form-group.is-focused label,
.mail-form form .form-group.is-focused label.control-label {
	color: #009688;
}
.mail-form form .btn-submit {
	background-color: #0083cb;
	font-size: 22px;
	font-family: 'proxima_nova_rgbold';
	color: #ffffff;
	border-radius: 0;
	padding: 10px 45px;
}
.contact-section address {
	margin-top: 70px;
	font-family: 'proxima_novalight';
	font-size: 18px;
	color: #797979;
	line-height: 1.2;
}
.contact-section address h5 {
	margin-bottom: 0;
	color: #9cca1d;
	text-transform: uppercase;
	font-family: 'proxima_nova_rgbold';
	font-size: 18px;
}
.map {
	padding-left: 50px;
}
.contact-section .contact-map {
	height: 430px;
	max-width: 530px;
	width: 100%;
	margin-bottom: 60px;
	border: 1px solid #A7A7A7;
}
.contact-section .map .email a span {
	font-family: 'proxima_nova_rgbold';
	font-size: 18px;
	color: #0083cb;
	vertical-align: middle;
}
.contact-section .map .email a .fa {
	font-size: 30px;
	margin-right: 10px;
	color: #ADD040;
	vertical-align: middle;
}
.contact-section .map .list-inline {
	margin-top: 40px;
}
.contact-section .map .list-inline a {
    border-radius: 100%;
    height: 50px;
    width: 50px;
    display: inline-table;
    vertical-align: middle;
    text-align: center;
}
.contact-section .map .list-inline a i {
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    color: #ADD040;
    border: 1px solid #ADD040;
    border-radius: 100%;
    font-size: 26px;
}
footer .footer-logo {
	background-color: #01273e;
	padding: 40px 0 30px;
}
footer .footer-logo p {
	font-family: 'proxima_novalight';
	font-size: 20px;
	color: #FFFFFF;
	margin-top: 10px;
}
footer .copyright {
	background-color: #001018;
	padding: 10px 0;
}
footer .copyright p {
	font-size: 14px;
	font-family: 'proxima_novalight';
	color: #0083cb;
}
footer .copyright p > a,
footer .copyright p > a:hover {
	color: #0083cb;
}
.top-section .navbar-default.sticky-header {
	position: fixed;
	top: 0;
	width: 100%;
	background-color: rgba(255,255,255,0.8);
	left: 0;
	z-index: 1;
	border-radius: 0;
}
.top-section .navbar-default.sticky-header .container .navbar-brand img {
	height: 80px;
}
.top-section .navbar-default.sticky-header .navbar-right {
	margin-top: 25px;
}
.modal-header .close {
	font-size: 40px;
}
.coming-soon .modal-body h4 {
	font-size: 36px;
	color: #0083CB;
	font-family: 'proxima_nova_rgbold';
}
.coming-soon .modal-body h4 span {
	color: #a6ce37;
}
.policy .modal-body h5 {
	font-weight: bold;
	color: #0067aa;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
	text-transform: uppercase;
}
.policy .modal-body h5:first-child {
	margin-top: 0;
}
.policy .modal-body li  {
	margin-bottom: 1rem;
}
/*Thanks Message*/
.success {
  padding: 1em;
  margin-bottom: 0.75rem;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  color: #468847;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  border-radius: 4px;
}
.error {
  padding: 1em;
  margin-bottom: 0.75rem;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  color: #b94a48;
  background-color: #f2dede;
  border: 1px solid rgba(185, 74, 72, 0.3);
  border-radius: 4px;
}
body.modal-open {
    overflow-y: hidden;
}
.modal-open .top-section .navbar-default.sticky-header {
	padding-right: 13px;
}
@media screen and (max-width: 1200px) {
	.img-sec {
		float: none;
		width: 100%;
	}
	.welcome-section .img-sec,
	.key-feature-section .img-sec {
		background-position: left;
	}
	.about-section .img-sec,
	.pricing-package-section .img-sec {
		background-position: right;
	}
	.text-sec,
	.about-section .text-sec,
	.key-feature-section .text-sec,
	.pricing-package-section .text-sec {
		padding-top: 50px;
	}
	.text-sec,
	.about-section .text-sec .content,
	.key-feature-section .text-sec .content,
	.pricing-package-section .text-sec .content {
		float: none;
		width: 100%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 20px;
	}
	.about-section .text-sec ul {
		padding-left: 0;
	}
	.welcome-section .img-sec .text-container,
	.about-section .text-container,
	.key-feature-section .img-sec .text-container,
	.pricing-package-section .img-sec .text-container {
		float: none;
		margin: 0 auto;
	}
	.pricing-package-section .package {
		margin-bottom: 50px;
	}
	.top-section .navbar-default.sticky-header .container .navbar-brand img {
		height: 60px;
	}
	.top-section .navbar-default.sticky-header .container .navbar-toggle {
		margin-top: 18px;
	}
}
@media screen and (max-width: 1035px) {
	.top-section .navbar-collapse li a {
		padding: 5px 8px;
	}	
}
@media screen and (max-width: 980px) {
	.map {
		padding-left: 15px;
	}
	.top-section .navbar-collapse li a {
		font-size: 15px;
	}
}
@media screen and (max-width: 767px) {
	.top-section .navbar-collapse li.login .invite {
		margin: 10px 0;
	}
}
@media screen and (max-width: 360px) {
	.about-section .text-sec li p,
	.about-section .text-sec li .fa
	{
		float: none;
		width: auto;
		display: inline-block;
	}
}
